import React from 'react'
import styled from '@emotion/styled'

const PartnerEmbedVideo = props => {
  const currentPartner = props.teamPartners
  const VideoEmbedContainer = styled.div``

  return <VideoEmbedContainer />
}

export default PartnerEmbedVideo
